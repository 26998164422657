import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled, fromTheme } from 'lib_ui-primitives';

const RightAlign = styled(View)`
    justify-content: flex-end;
    flex-grow: 1;
    background-color: ${({darkenBackground}) => darkenBackground ?
        fromTheme('layout', 'backgroundColorDarker') :
        undefined };
`;

function RightAlignLayout(props) {
    const { id, title: name, hNode, children, ...otherProps } = props;
    const darkenBackground = /true/i.test(hNode?.darkenBackground);
    return rc(RightAlign, { name, id, darkenBackground, ...otherProps }, children);
}

RightAlignLayout.propTypes = {
    children: PropTypes.array
};

export default RightAlignLayout;
