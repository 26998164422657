import { COMMON_COLOR_SCHEME as scheme, COLORS, CHART_COLORS } from 'lib_ui-primitives';
import zindex from './zindexes';

const SIZE = {
    XS: 3,
    SMALL: 6,
    MEDIUM: 12,
    LARGE: 16,
    XL: 24
};

export default function themeValues(otherThemeValues = { mobile: false }) {
    const { darkMode = false, mobile = false, tablet = false, width, height, smallMobile } = otherThemeValues;
    let theme = {
        zindex,
        // eslint-disable-next-line no-undef
        native: __SST_REACT_NATIVE__ || __TEST_RFID__,
        viewPort: mobile ? 'MOBILE' : tablet ? 'TABLET' : 'DESKTOP',
        smallMobile,
        colorScheme: scheme,
        activeBorderColor: scheme.primary,
        selectedBorderColor: darkMode ? scheme['border-neutral'] : scheme.border,
        borderColor: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
        baseBackgroundColor: darkMode ? '#000' : scheme.background,
        baseBackgroundColorDarker: darkMode ? '#333' : '#ccc',
        // with each layer, progressively lightens/darkens the parent or baseBackgroundColor
        backgroundColorLighter: darkMode ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.02)',
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, 0.06)',
        backgroundColorDarker: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        backgroundColorOnDarkColors: darkMode ? 'rgb(65, 65, 65)' : 'rgb(187, 187, 187)',
        backgroundModal: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
        backgroundHint: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
        disabledBackgroundColor: scheme['disabled-background'],
        boxShadowColor: darkMode ? '#ffffff50' : '#00000050',
        defaultFontColor: darkMode ? scheme['white-text'] : COLORS.trueBlack,
        invertedFontColor: darkMode ? COLORS.trueBlack : scheme['white-text'],
        disabledFontColor: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)',
        lighterDisabledFontColor: darkMode ? 'rgba(255, 255, 0, 0.55)' : 'rgba(0, 0, 0, 0.25)',
        fontColorForDarkBackground: scheme['white-text'],
        errorFontColor: scheme.error,
        unobtrusiveFontColor: darkMode ? '#bbbbbb' : '#444444',
        subheadingFontColor: darkMode ? scheme['light-accent-text'] : scheme['dark-accent-text'],
        font: 'Roboto',
        fontAlternate: 'Nunito',
        fontSizeSmall: SIZE.MEDIUM,
        fontSize: SIZE.LARGE,
        fontSizeLarge: SIZE.XL,
        fontSizeLabel: SIZE.MEDIUM,
        disabledColor: scheme['disabled-color'],
        drawerWidth: mobile ? width : 240,
        gridLineColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        listLineHeight: 35,
        listActiveBackgroundColor: darkMode ? '#D0EDF866' : scheme['accent-1-highlight'],
        listSelectedBackgroundColor: darkMode ? '#D0EDF866' : scheme['accent-1-highlight'],
        columnHeaderResizeHandle: darkMode ? '#D0EDF866' : '#ffffff50',
        columnWidthToHeightRatio: 0.66,
        progressBarColor: scheme['primary'],
        borderRadius: SIZE.XS,
        viewPadding: SIZE.SMALL,
        viewPaddingMore: SIZE.MEDIUM,
        viewMargin: SIZE.SMALL,
        viewMarginMore: SIZE.MEDIUM,
        textMargin: mobile ? SIZE.XS : SIZE.SMALL,
        textPadding: SIZE.SMALL,
        textPaddingMore: SIZE.MEDIUM,
        minModalWidth: mobile ? width : 300,
        /* For whatever reason, 90% does not yield the same value as width * 0.9 */
        maxModalWidth: mobile ? width : width * 0.9,
        minModalHeight: mobile ? height : 0,
        maxTableColumnWidth: 200,
        maxTableCharLength: 255,
        spinnerColor: darkMode ? scheme['white-text'] : scheme['primary'],
        iconFont: 'material-icons',
        iconSize: SIZE.XL,
        caretColor: darkMode ? scheme['white-text'] : scheme['black-text'],
        labelColor: darkMode ? scheme['white-text'] : scheme['black-text'],
        labelForEmptyField: darkMode ? scheme['light-action-available'] : scheme['dark-action-available'],
        linkColor: darkMode ? COLORS.lightBlue : COLORS.blue,
        linkFontSize: SIZE.MEDIUM,
        disabledLabelColor: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)',
        button: {
            fontSize: SIZE.LARGE,
            borderRadius: SIZE.XS,
            roundDiameter: 2 * SIZE.LARGE,
            roundDiameterLarge: 48,
            // If darkMode uses lighter colors, then invert the text for contrast
            //fontColor: darkMode ? scheme['black-text'] : scheme['white-text'],
            fontColor: scheme['white-text'],
            height: 2 * SIZE.LARGE,
            maxHeight: 40,
            maxWidth: 200,
            minWidth: 2 * SIZE.LARGE,
            padding: 8,
            primary: scheme['primary'],
            primaryHover: scheme['primary-hover'],
            primaryHighlight: scheme['primary-highlight'],
            success: scheme['success'],
            successHover: scheme['success-hover'],
            successHighlight: scheme['success-highlight'],
            warn: scheme.warn,
            warnHover: scheme['warn-hover'],
            warnHighlight: scheme['warn-highlight'],
            error: scheme.error,
            errorHover: scheme['error-hover'],
            errorHighlight: scheme['error-highlight'],
            negative: scheme.error,
            negativeHover: scheme['error-hover'],
            negativeHighlight: scheme['error-highlight'],
            transparent: '#00000000',
            transparentHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            transparentHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            base: darkMode ? '#000' : scheme.background,
            baseHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            baseHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            accentOne: scheme['accent-1'],
            accentOneHover: scheme['accent-1-hover'],
            accentOneHighlight: scheme['accent-1-highlight'],
            accentTwo: scheme['accent-2'],
            accentTwoHover: scheme['accent-2-hover'],
            accentTwoHighlight: scheme['accent-2-highlight'],
            // For pseudo-disabled components that still require interaction (like notifications button)
            gray: COLORS.darkGray,
            grayHover: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            grayHighlight: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'
        },
        card: {
            padding: SIZE.LARGE,
            // Header matches selected navHeadings color scheme and header colors from UI v1
            // Don't worry, looks fine on darkmode
            header: {
                textColor: scheme['white-text'],
                backgroundColor: scheme['background-dark']
            },
            body: {},
            footer: {
                backgroundColor: 'transparent',
                paddingY: 8
            }
        },
        charts: {
            legendNavActive: darkMode ? scheme['white-text'] : scheme['black-text'],
            legendHoverColor: darkMode ? scheme['primary-hover'] : scheme['primary'],
            legendBackgroundColor: darkMode ? '#00000090' : '#ffffff90'
        },
        chartColors: darkMode ? CHART_COLORS.dark : CHART_COLORS.light,
        dataAggregation: {
            fontColor: darkMode ? scheme['primary-hover'] : scheme.primary
        },
        form: {
            // These pseudo-static sizes are used for FormField components (which encapsulate
            // most form data entry controls).
            // This seems to work well with tested useCases, but it is possible that
            // additional nesting of fieldsets within fieldsets might create less
            // aesthetically pleasing results and a more dynamic approach might be needed.
            entryMinWidth: Math.min(280, width - 30),
            entryMaxWidth: Math.min(350, width - 30),
            inputBorderRadius: SIZE.XS,
            dropdownMinHeight: 200
        },
        menu: {
            borderColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            backgroundColor: darkMode ? 'rgb(51, 51, 51)' : 'rgb(204, 204, 204)',
            menuItemPadding: SIZE.MEDIUM
        },
        outerMenus: {
            backgroundColor: scheme['background-medium'],
            border: scheme['border'],
            borderWidth: 4,
            chevronColor: scheme['white-text'],
            chevronColorHover: scheme['white-text'],
            fontColor: scheme['white-text'],
            fontColorHover: scheme['white-text'],
            fontColorActive: scheme['white-text'],
            disabledBackgroundColor: 'rgba(0, 0, 0, 0.2)',
            disabledFontColor: 'rgba(255, 255, 255, 0.45)',
            headerHeight: 60,
            margin: SIZE.MEDIUM,
            menuItemPadding: SIZE.MEDIUM,
            navHeaderBackground: 'transparent',
            navHeaderBorder: 'transparent',
            navHeaderBackgroundHover: scheme['background-dark'],
            navHeaderBackgroundActive: scheme['background-dark'],
            navHeaderBorderHover: scheme['background-dark'],
            navHeaderDisabledBackground: 'transparent',
            navHeaderDisabledBorder: 'transparent',
            navHeaderLinkBackground: scheme['background-dark'],
            navHeaderLinkBorder: scheme['background-dark'],
            navHeaderLinkBackgroundHover: scheme['background-dark'],
            navHeaderLinkBorderHover: scheme['background-dark'],
            navHeaderLinkBackgroundActive: scheme['background-dark'],
            navHeaderLinkBorderActive: scheme['background-dark']
        },
        slider: {
            selected: darkMode ? scheme['primary'] : scheme['primary-hover'],
            hover: darkMode ? scheme['primary-hover'] : scheme['primary'],
            backgroundColor: darkMode ? scheme['disabled-background'] : scheme['background-light'],
            disabled: darkMode ? 'rgba(255, 255, 255, 0.55)' : 'rgba(0, 0, 0, 0.55)'
        },
        switch: {
            checked: scheme['primary'],
            unchecked: darkMode ? scheme['primary-hover'] : scheme['dark-action-available'],
            disabled: scheme['disabled-color'],
            intermediate: scheme['accent-2'],
            backgroundColorChecked: COLORS.paleGreen, // darkMode ? scheme['disabled-background'] : scheme['primary-highlight'],
            backgroundColorUnchecked: COLORS.paleRed,
            disabledBackgroundColor: scheme['disabled-background'],
            backgroundColorIntermediate: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'
        },
        table: {
            headerColor: scheme['background-dark']
        },
        layout: {
            backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, 0.06)',
            backgroundColorDarker: darkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)'
        },
        ...otherThemeValues
    };
    if (mobile) {
        theme = {
            ...theme,
            listLineHeight: 50,
            borderRadius: 4,
            iconSize: 2 * SIZE.LARGE,
            button: {
                ...theme.button,
                roundDiameter: 3 * SIZE.LARGE,
                roundDiameterLarge: 56,
                roundBorderRadius: 28, // % doesn't work on react native
                height: 4 * SIZE.LARGE,
                minWidth: 4 * SIZE.LARGE,
                // Remove hover states for mobile.
                successHover: undefined,
                primaryHover: undefined,
                errorHover: undefined,
                warnHover: undefined
            },
            card: {
                ...theme.card,
                padding: SIZE.SMALL
            },
            form: {
                ...theme.form,
                entryMinWidth: Math.min(smallMobile ? 175 : 280, width - 48),
                dropdownMinHeight: '100%'
            },
            outerMenus: {
                ...theme.outerMenus,
                margin: 0,
                menuItemPadding: SIZE.XL
            }
        };
    }

    return theme;
}
