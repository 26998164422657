import { createElement as rc, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _Range from '../../_abstractComponent/Range';
import useDbView from '../../../hooks/useDbView';
import { contexts, fromTheme, styled, View, hooks } from 'lib_ui-primitives';
import { constants, filters, metadata } from 'lib_ui-services';

const { useBbState } = hooks;
const { SCOPE, VISIBILITY, DURATION } = constants.retention;
const PER_ROUTE = {
    scope: SCOPE.PER_ROUTE,
    duration: DURATION.SESSION,
    visibility: VISIBILITY.PER_BROWSER
};

const RangeSearchElement = styled(View).attrs({ name: 'range-search-element' })`
    margin-left: 4px;
    padding-left: ${fromTheme('viewPadding')};
    margin-top: ${fromTheme('viewMargin')};
    padding-top: ${fromTheme('viewPadding')};
    overflow: visible;
`;
RangeSearchElement.displayName = 'RangeSearchElement';

const rangeTypes = {
    DateTime: { filter: 'dateRange', childComponentType: 'DatePicker' }
};

const _p = { useDbView, useBbState };
export const _private = _p;
export default function Range(props) {
    const {
        hNode,
        hNode: { propertyName, id, fromInputHNode, filters: { namespace = '__none', relation = '__none' } = {} }
    } = props ?? { hNode: {} };

    // Get the default date range if available
    const defaultFilters = useMemo(() => filters.filterHelpers.getDefaultFilters(hNode), [hNode]);
    const defaultFromValue = defaultFilters?.dateRange?.startDateTime;
    const defaultToValue = defaultFilters?.dateRange?.endDateTime;

    // If a default date range has been set, use that as the initial value, otherwise fallback to undefined.
    // On navigation to a page with a default date range, the to/from dates will be displayed on the date picker, and the grid will be filtered.
    const [fromValue, setFromValue] = _p.useBbState(
        defaultFromValue ?? undefined,
        `from-${propertyName}-${id}`,
        PER_ROUTE
    );
    const [toValue, setToValue] = _p.useBbState(defaultToValue ?? undefined, `to-${propertyName}-${id}`, PER_ROUTE);

    const filterType = rangeTypes[fromInputHNode.hNodeType].filter;
    const propertyPath = metadata.getPathToProperty(hNode);

    const { viewCriteria } = _p.useDbView(namespace, relation, undefined, hNode);
    const valueChanged = useContext(contexts.SearchContext);
    useEffect(() => {
        if (!viewCriteria) return;
        const filter = {
            [filterType]: filters[filterType].getFilter(fromValue, toValue, propertyPath, fromInputHNode.format, true)
        };

        // Set the filters for the viewCriteria so that there isn't a delay in filtering the grid on navigation when a default date range is set.
        viewCriteria.applyFilters(
            {
                ...defaultFilters,
                ...filter
            },
            id
        );

        // SearchPane uses a `searchCriteria` filter, so it needs the mongodb syntax
        // Passing in `filters` as I can't overcome recursion and circular dependencies in a different way for nestedListEntry... :(
        const mongoSyntax = filters[filterType].getMql(filter);
        valueChanged(hNode, mongoSyntax);
    }, [
        valueChanged,
        hNode,
        propertyPath,
        fromValue,
        toValue,
        fromInputHNode.format,
        filterType,
        id,
        viewCriteria,
        defaultFilters
    ]);

    return rc(
        RangeSearchElement,
        null,
        rc(_Range, {
            ...props,
            fromValue,
            setFromValue,
            toValue,
            setToValue,
            rangeTypes
        })
    );
}

Range.propTypes = {
    hNode: PropTypes.shape({
        propertyName: PropTypes.string,
        id: PropTypes.string.isRequired,
        fromInputHNode: PropTypes.shape({
            hNodeType: PropTypes.string.isRequired,
            format: PropTypes.string
        }).isRequired
    }).isRequired
};
