import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, styled, fromTheme } from 'lib_ui-primitives';

const SpaceApart = styled(View)`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: ${({darkenBackground}) => darkenBackground ?
        fromTheme('layout', 'backgroundColorDarker') :
        undefined };
`;

function SpaceApartLayout(props) {
    const { id, title: name, hNode, children, ...otherProps } = props;
    const darkenBackground = /true/i.test(hNode?.darkenBackground);
    return rc(SpaceApart, { name, id, darkenBackground, ...otherProps }, children);
}

SpaceApartLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default SpaceApartLayout;
