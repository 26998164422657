import { useEffect, useRef, useReducer, useState, useCallback, useMemo } from 'react';
import { database, metadata, constants, dbViews } from 'lib_ui-services';
import { useFilterInterdependencyBoundary } from '../../components/contextProviders/FilterInterdependencyBoundary';
import useFilterMetadata from '../useFilterMetadata';
import useGetDbViewName from './useGetDbViewName';
import useProfileRole from '../useProfileRole';
import logging from '@sstdev/lib_logging';
import useEventSink from '../useEventSink';
import { hooks, useIsAnimating } from 'lib_ui-primitives';

// For comparing hook runs to see what changed.  See 'FOR DEBUGGING' comment below
// import { useWhatChanged } from '@simbathesailor/use-what-changed';

const { useRouter } = hooks;
const { emptyDbView, getDbView } = dbViews;

const _p = {
    getDbView,
    useRouter,
    useFilterInterdependencyBoundary,
    database,
    getRelationMetadata: metadata.getRelationMetadata,
    useProfileRole
};
export const _private = _p;

/**
 * Similar to useSelector from react-redux but with a loki backing store
 * https://github.com/reduxjs/react-redux/blob/96bf941751a8460c5cf64027348f05d332e19a20/src/hooks/useSelector.js
 * @param {string} namespaceTitle
 * @param {string} relationTitle
 * @param viewName The name (unique identifier) of the dynamic view in loki
 * @param hNode - An hNode (such as a TableView or DropDown) that has 'filter' hNodeTypeGroup children
 * @param {boolean} beginDatabaseAccess - because this is a hook (and therefore can not be conditionally called) explicitly waiting for the consumer to say they are ready can avoid eager database operations.
 * @param {boolean} releaseOnExit - Secondary access (like from PrintPreview or action buttons) should not release the view
 */
export default function useDbView(
    namespaceTitle,
    relationTitle,
    viewName,
    hNode,
    _beginDatabaseAccess = true,
    releaseOnExit = true
) {
    const doNoOp = namespaceTitle == '__none' || relationTitle == '__none';
    const isAnimating = useIsAnimating();
    if (hNode?.id == null) {
        throw new Error('A hNode with `id` must be specified to differentiate various consumers of the dynamic view.');
    }
    const [subscribe] = useEventSink();
    const [viewReady, setViewReady] = useState(false);
    viewName = useGetDbViewName(namespaceTitle, relationTitle, viewName);

    // Gather metadata based filter information (if it exists) for view.
    const metadataBasedFilters = useFilterMetadata(hNode);
    // special case, if the role isn't known yet, we shouldn't try to access the database yet!
    // The roles are synced as part of the initial sync.
    // When that is complete, the current user's role is loaded into memory in didUpdate_application_useCase_syncAllDataToLocal.js
    // and only after that can we figure out IF we need to filter the data because of the role, and HOW to filter it if so.
    const [, roleIsReady] = useProfileRole();
    const beginDatabaseAccess = _beginDatabaseAccess && roleIsReady;

    // Get a filter boundary (mainly for cascading dropdowns)
    const FilterInterdependencyBoundary = _p.useFilterInterdependencyBoundary();
    const view = useRef(emptyDbView);
    // Trick to allow us to tell react to rerender the consuming component if data changes
    const [, forceRender] = useReducer(s => s + 1, 0);

    useEffect(() => {
        // When unmounting, remove filters created by this component
        return () => {
            if (releaseOnExit) {
                view?.current?.viewCriteria.removeFilters(hNode.id);
            }
        };
    }, [hNode, releaseOnExit]);

    // FOR DEBUGGING:
    // If you need to compare runs of the following useEffect hook to see what changed, uncomment the following two lines
    // and put `deps` as your dependency array.  Also verify that these lines still has the same dependencies as the hook. :)
    // const deps = [namespace, relation, viewName, FilterInterdependencyBoundary, filterMetadata, hNode];
    // useWhatChanged(deps, 'namespace, relation, viewName, FilterInterdependencyBoundary, filterMetadata, hNode');

    // Get a view containing data, apply default (i.e. metadata specified)
    // filters to it, and listen for changes to it.
    useEffect(() => {
        const cleanup = {};
        let canUpdateState = true;
        async function doAsync() {
            const _view = await _p.getDbView(namespaceTitle, relationTitle, viewName);

            // If the view changes, force a new render of the consuming component.
            function rebuildListener() {
                try {
                    if (!canUpdateState) return;
                    let allFiltersApplied = _view.viewCriteria.areFiltersApplied(metadataBasedFilters, hNode.id);
                    setViewReady(isReady => {
                        let shouldRender = false;
                        // If the data is already ready, force a new render because
                        // state will not change (and will therefore not do it for you).
                        if (isReady && allFiltersApplied) {
                            shouldRender = true;
                        }
                        logging.debug(
                            `[DBVIEW] rebuild listener ${viewName} - is ready - previously: ${isReady} and now: ${allFiltersApplied}`
                        );
                        if (!allFiltersApplied) {
                            logging.debug(
                                `[DBVIEW] - metadataBasedFilters: ${JSON.stringify(
                                    _view.viewCriteria?.calculateFilters?.(metadataBasedFilters, _view.viewCriteria)
                                )}`
                            );
                        }
                        if (shouldRender) {
                            forceRender();
                        }
                        return allFiltersApplied;
                    });
                } catch (error) {
                    logging.error(error);
                }
            }
            try {
                _view.rebuildListeners.push(rebuildListener);
                view.current = _view;
                // store a scoped copy of the view ref for cleaning up.
                if (releaseOnExit) {
                    cleanup.view = _view;
                }
                // Apply any filter blocks attached as children to the component consuming this hook
                //     - For example, a dropdown that has filter metadata associated with it.
                // Even apply when no filters are explicitly defined to apply any default filters.
                let filtersChanged =
                    FilterInterdependencyBoundary?.addFilterMetadata(_view, metadataBasedFilters) || false;
                // Avoid trying to apply filters when there are no filter hNode children.  This is because any other
                // component that creates filters for their node id (such as the SearchPane) will have their filters
                // removed by applyFilters if no filter hNode children exist.
                const hasFilterHNodeChildren =
                    hNode?.children?.some(c => c.hNodeTypeGroup === 'filter' || c.hNodeType === 'PatchDetail') ?? false;
                if (hasFilterHNodeChildren) {
                    const thisFilterChanged = await _view.viewCriteria.applyFilters(metadataBasedFilters, hNode.id);
                    filtersChanged = thisFilterChanged || filtersChanged;
                }
                if (!filtersChanged) {
                    setViewReady(true);
                }
            } catch (error) {
                logging.debug(`[useDbView] an error occurred while setting up the ${viewName} dbview.`);
                logging.error(error);
            }
        }
        if (!doNoOp && beginDatabaseAccess && !isAnimating) doAsync();
        // Cleanup
        return () => {
            canUpdateState = false;
            cleanup.view?.release();
        };
        // }, deps); //uncomment this line and comment out the next to use useWhatChanged
    }, [
        namespaceTitle,
        relationTitle,
        viewName,
        FilterInterdependencyBoundary,
        metadataBasedFilters,
        hNode,
        beginDatabaseAccess,
        releaseOnExit,
        isAnimating,
        doNoOp
    ]);

    /**
     * Request the latest changes for this relation when the dbView is rendered if this
     * is a limited sync size relation.
     * Also listen for the user to request additional pages using the 'refresh' verb.
     * It might make sense to change this verb to 'fetch' at some point.
     */
    useEffect(() => {
        const relation = _p.getRelationMetadata(namespaceTitle, relationTitle, false);
        // Some relations (like application/notification) have no metadata, but they
        // also cannot be synchronized, so skip.
        if (relation != null) {
            const doAsync = async () => {
                const db = _p.database.get();
                if (relation.limitSyncSize) {
                    await db.synchronization.syncOneRelation(
                        { namespaceTitle, relationTitle },
                        constants.SYNC_TYPES.sizeLimitedLatestOnly
                    );
                }
            };
            if (!doNoOp && beginDatabaseAccess) doAsync();
        }
    }, [subscribe, namespaceTitle, relationTitle, beginDatabaseAccess, doNoOp]);

    const requiresPostProcessing = useMemo(() => {
        const { aggregateType, groupBy, groupByBoolean, patchDetail } = metadataBasedFilters;
        return (aggregateType || groupBy || groupByBoolean || patchDetail) != null;
    }, [metadataBasedFilters]);

    const [dataModel, setDataModel] = useState({});

    useEffect(() => {
        let allowStateUpdate = true;
        async function doAsync() {
            const dd = await getDataModel(namespaceTitle, relationTitle?.replace('-patch', ''));
            if (allowStateUpdate) {
                if (dd == null) {
                    logging.warn('No data dictionary was found to determine property types for the model.');
                    setDataModel({});
                } else {
                    setDataModel(dd);
                }
            }
        }
        if (!doNoOp) doAsync();
        return () => (allowStateUpdate = false);
    }, [doNoOp, namespaceTitle, relationTitle]);

    const calculateAggregateResult = useCallback(() => {
        return view.current.viewCriteria.calculateAggregateResult(metadataBasedFilters, view.current.count, dataModel);
    }, [metadataBasedFilters, dataModel]);

    return {
        requiresPostProcessing,
        calculateAggregateResult,
        records: view.current.data,
        recordCount: view.current.count,
        viewCriteria: view.current.viewCriteria,
        viewContainsId: view.current.containsId,
        viewReady: viewReady
    };
}

async function getDataModel(namespace, relation) {
    const dataModel = await metadata.getDictionary();
    return dataModel?.[namespace]?.[relation] || {};
}
