import { getRouter } from 'lib_ui-services';

export default {
    verb: 'doingChange',
    namespace: 'application',
    relation: 'route',
    description: 'Change the route ',
    //this is the actual logic:
    logic
};
export const _private = { getRouter };

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @template T
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function logic({ data, context, log, dispatch }) {
    let targetPath = data?.targetPath || context?.type;
    if (targetPath == null) {
        throw new Error('No target path provided.');
    }
    log?.debug(`[NAVIGATION] Route change requested. target is ${targetPath}.`);
    const router = _private.getRouter();
    dispatch(
        {
            navigate: {
                doRouteChange: () => {
                    if (data?.search) {
                        targetPath += data.search;
                    }
                    router.goToLocation(targetPath);
                },
                router: { ...router, location }
            }
        },
        {
            verb: 'navigate',
            namespace: 'application',
            relation: 'route',
            routePath: targetPath
        }
    );
}
