import getShortProfileForMeta from '../../../../utilities/getShortProfileForMeta';
import log from '@sstdev/lib_logging';
import lodash from 'lodash';
import { constants } from 'lib_ui-services';
const { omit } = lodash;


/**
 * @type {import('../../../extendedRulesArrayRepository').extendedRule}
 */
export default {
    verb: 'willCreate',
    namespace: 'transaction',
    description: 'proactively adjust the applicable stock levels',
    useCaseIds: [constants.useCaseIds.REDBEAM_INVENTORY_TRACKING],
    prerequisites: [
        // we want the existing stock, in case it already exists
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'stock',
                type: 'find'
            },
            query: async ({ data }) => {

                let { 'item:item': item } = data.newRecord;

                let locationIds = [];

                if (data.newRecord.receive?.['location:location']) {
                    locationIds.push(data.newRecord.receive['location:location']._id);
                }
                if (data.newRecord.issue?.['location:location']) {
                    locationIds.push(data.newRecord.issue['location:location']._id);
                }
                if (!locationIds.length) {
                    throw new Error('Location not found.');
                }

                return {
                    'item:item._id': item._id,
                    'location:location._id': {$in: locationIds},
                    'meta.deleted': { $exists: false }
                };
            }
        },
        // but get the item just in case the stock doesn't exist, and we need to create it.
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                const { 'item:item': item } = data.newRecord;
                return {
                    _id: item._id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: willCreate
};

/**
 * @param {object} params
 * @param {any} params.data
 * @param {Array<{result:[]}>} params.prerequisiteResults
 * @param {(data:object, context:Context, awaitResult?:boolean)=>Promise<void|any>} params.dispatch
 * @param {import('../../../extendedRulesArrayRepository').Context} params.context
 */
async function willCreate({ data, prerequisiteResults, dispatch, context }) {
    const [
        { result: existingStock = [] },
        { result: [item] = [] }
    ] = prerequisiteResults;

    let existingReceiveStock, existingIssueStock;

    if (data.newRecord.receive?.['location:location']) {
        const receiveStock = existingStock.filter(stock => stock['location:location']._id === data.newRecord.receive['location:location']._id);
        if (receiveStock.length > 1) {
            throw new Error(`Multiple stock records found for same location: ${data.newRecord.receive['location:location']._id}`);
        }
        if (receiveStock.length) {
            existingReceiveStock = receiveStock[0];
        }
    }
    if (data.newRecord.issue?.['location:location']) {
        const issueStock = existingStock.filter(stock => stock['location:location']._id === data.newRecord.issue['location:location']._id);
        if (issueStock.length > 1) {
            throw new Error(`Multiple stock records found for same location: ${data.newRecord.issue['location:location']._id}`);
        }
        if (issueStock.length) {
            existingIssueStock = issueStock[0];
        }
    }

    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    const meta = {
        createdBy: currentUser,
        createdTime: currentTime,
        modifiedBy: currentUser,
        modifiedTime: currentTime,
        serverModifiedTime: currentTime
    };

    if (['receive', 'transfer', 'adjust'].includes(context.relation)) {   // there is a receive component
        if (existingReceiveStock) {
            // we already have stock, so we just need to update it.

            let quantity = data.newRecord.quantity;
            if (context.relation !== 'adjust') {   // either receiving by itself or receiving half of transfer
                quantity += existingReceiveStock.quantity;
            }

            await updateStock({ context, existingStock: existingReceiveStock, quantity, currentUser, currentTime, dispatch });

        } else {
            // we need to create the stock at the receive location
            const relation = 'receive';
            const quantity = data.newRecord.quantity;
            await createStock({ data, context, relation, item, quantity, meta, dispatch });
        }
    }
    if (['issue', 'transfer'].includes(context.relation)) {    // there is an issue component
        if (existingIssueStock) {
            // we already have stock, so we just need to update it.
            const quantity = existingIssueStock.quantity - data.newRecord.quantity;

            await updateStock({ context, existingStock: existingIssueStock, quantity, currentUser, currentTime, dispatch });

        } else {
            // we need to create the stock at the issue location
            const relation = 'issue';
            const quantity = -data.newRecord.quantity;
            await createStock({ data, context, relation, item, quantity, meta, dispatch });
        }
    }

    return data;
}

async function updateStock({ context, existingStock, quantity, currentUser, currentTime, dispatch }){
    await dispatch(
        {
            isNew: false,
            items: [
                {
                    ...existingStock,
                    quantity,
                    meta: {
                        ...existingStock.meta,
                        modifiedBy: currentUser,
                        modifiedTime: currentTime,
                        serverModifiedTime: currentTime
                    }
                }
            ]
        },
        {
            // using upsert to avoid the update going to the server:
            ...context,
            verb: 'upsert',
            namespace: 'item',
            relation: 'stock',
            type: undefined
        }
    );
}

async function createStock({ data, context, relation, item, quantity, meta, dispatch }) {
    let {
        [relation]: { 'location:location': location, 'location:building': building, 'location:company': company } = {}
    } = data.newRecord;

    if (!location || !building || !company) {
        throw new Error('Location not at expected place in object.');
    }

    const { _id, assetNo, locations = [], ...rest } = omit(item, '$loki', '_idx');

    const companyReorderInfo = locations.filter(l => l['location:company']._id === location._id);
    let reorderInfo = {};
    if (locations.length) {
        if (companyReorderInfo.length === 0) {
            log.warn(`item ${assetNo} is not available at ${company.title} ${building.title} ${location.title}`);
        } else if (companyReorderInfo.length === 1) {
            reorderInfo = companyReorderInfo[0];
        } else {
            reorderInfo =
                companyReorderInfo.find(l => l['location:building']._id === building._id) ||
                companyReorderInfo.find(l => !l['location:building']);
        }
    }

    await dispatch(
        {
            isNew: true,
            items: [
                {
                    _id: data.newRecord._id,
                    title: `${assetNo} @ ${location._id}`,
                    quantity,
                    'item:item': { _id, assetNo },
                    ...rest,
                    ...reorderInfo,
                    'location:company': company,
                    'location:building': building,
                    'location:location': location,
                    meta
                }
            ]
        },
        {
            // using upsert to avoid the update going to the server
            ...context,
            verb: 'upsert',
            namespace: 'item',
            relation: 'stock',
            type: undefined
        }
    );
}
