import { constants, http, httpUtilities, session } from 'lib_ui-services';

const { useCaseIds } = constants;

export const _private = {
    post: http.post,
    enableSync: session.enableSync,
    disableSync: session.disableSync,
    clearHaven: session.clearHaven
};

export default {
    verb: 'doingCreate',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    prerequisites: [],
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    description: 'Used to create a haven (as opposed to a normal environment)',
    //this is the actual logic:
    logic: createHaven,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function createHaven({ data, context, dispatch }) {
    // Probably need to rethink some of this
    // to use Auth0 better -- relegating more responsibility to that platform.
    // Havens are currently only for sst users and we don't always use Auth0.
    // For security in case of SST user termination, SST users would ideally
    // only login to backbone using our Auth0
    // credentials unless it is for a (short-lived) haven.  In practice this is
    // tricky because we need to support normal user/password logins in prod, so
    // an SST user that has access to prod Nucleus could create a user/password
    // login.  Maybe we need to lock down prod to prevent addition of users to
    // tenants and  creation of new users except via the normal process (i.e. not through
    // Nucleus).  This would prevent an SST user from accessing a production tenant's
    // data.

    // Pass auth data at the same time so that the tenant selection
    // can be set to the newly created tenant inside the haven.
    // Unless there is more than one tenant or usecase created ... in which
    // case server should trigger the TenantSelection again after the
    // haven is created.
    const { userName, password } = context.user;
    const { newRecord } = data;
    const meta = newRecord.meta || {};
    delete newRecord.meta;
    const combinedRecord = {
        meta,
        haven: newRecord,
        auth: {
            userName,
            password
        }
    };

    const httpHeaders = httpUtilities.getHttpHeaders({
        ...meta,
        correlationId: newRecord.correlationId
    });
    const path = '/api/deploy/haven';
    const result = await _private.post(path, combinedRecord, httpHeaders);
    const { tenantId, appId: useCaseId, haven } = result;

    if (tenantId == null) {
        throw new Error('No tenant was created for the haven.');
    }
    if (useCaseId == null) {
        throw new Error(`No use case was added for the haven ${haven.title} tenant.`);
    }

    // If we try to sync while the haven is being created, it will fail since some of the services will not be available
    await _private.disableSync();

    // Log the user into the new tenant (but don't sync yet)
    // This will establish a socket connection that will be used to know when the haven is ready
    // silo_rules-engine will send the signal to sync once the haven is ready
    // see silo_rules-engine/src/server/rules/deploy/create_deploy_haven/create_deploy_haven_success.js
    // actually enabling the sync happens in lib_ui-services/src/socket/messageHandlers.js
    // on the upsert_deploy_haven_success event
    dispatch(
        {
            tenantId,
            useCaseId,
            haven,
            email: userName,
            userName,
            password,
            type: 'selectTenant'
        },
        { verb: 'update', namespace: 'security', relation: 'profile', type: 'selectTenant' }
    );

    data.result = result;
    return data;
}

/**
 * In the event of an error, clear the haven and re-enable sync.
 *
 * @param {{
 *      error: Error;
 *      data: T;
 *      context: Context;
 *      dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>,
 *      workflowStack: WorkflowStack[]
 * }} parameters
 * */
async function onError({ error }) {
    await _private.clearHaven();
    await _private.enableSync();
    throw error;
}
