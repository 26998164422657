import logging from '@sstdev/lib_logging';
import handleBackboneMessages from './messageHandlers';
import * as util from 'util';
import { cache } from '../authentication';
const { inspect } = util;

const _p = { handleBackboneMessages, getCurrentSession: cache.getCurrentSession };

export const _private = _p;
export default function socketEventHandlers(socketState) {
    // == Connect ==
    socketState.socket.on('connect', async () => {
        logging.info('[SOCKETS] connect.');
        socketState.updateSocketStatus(true);

        const { tenant, useCase } = await getActiveUseCaseAndTenant();
        const activeTenant = tenant['identity:tenant'];
        const activeUseCase = useCase['metaui:useCase'];
        socketState.socket.emit('selectTenant', { activeTenant, activeUseCase });
    });

    // == disconnect ==
    socketState.socket.on('disconnect', function () {
        logging.info('[SOCKETS] disconnecting.');
        socketState.updateSocketStatus(false);
        if (socketState.offline) {
            logging.info('[SOCKETS] Setting socket offline.');
            return;
        }
        if (!socketState.initialStartup) {
            logging.info('[SOCKETS] Socket communication failed ');
        } else {
            socketState.initialStartup = false;
        }
    });

    // == The actual event processing ==
    if (!socketState.socket.onEventReplaced) {
        // by default, you do not get access to the "event name" (e.g. "upsert_item_item")
        // Additionally, you have to explicitly subscribe to the event name to handle it.
        // we want access to the event name, as we don't _know_ what it exactly will be.
        // Therefore, this is intercepting the normal onevent call, then does the call as normal (just in case),
        // and then IF the event name includes an underscore, call to our custom event handler
        const onevent = socketState.socket.onevent;
        socketState.socket.onevent = function (packet) {
            onevent.call(this, packet); // original call
            const args = packet.data || [];
            if (args.length && args[0].includes('_')) {
                const [event, ...data] = args;
                _p.handleBackboneMessages(event, data, socketState.eventSink);
            }
        };
        socketState.socket.onEventReplaced = true;
    }

    // == Error handling ==
    socketState.socket.removeAllListeners('error');
    socketState.socket.on('error', function (data) {
        logging.error(`[SOCKETS] An error occurred in the socket communication.  Details: ${inspect(data)}`);
    });

    // == bonus chat ==
    socketState.socket.removeAllListeners('chat');
    socketState.socket.on('chat', function (data) {
        handleBackboneMessages('chat', data, socketState.eventSink);
    });
}

/**
 * Retrieves the active use case and tenant for the given user.
 *
 * @param {Object} session - The user object.
 * @returns {Promise<{useCase:object, tenant:object}>} - A promise that resolves to the active use case and tenant object.
 */
export async function getActiveUseCaseAndTenant() {
    const session = await _p.getCurrentSession();
    // when logging in with the proprietary login, the tenant & usecase are stored in tenantId & appId
    // when logging in through auth0, the tenant & usecase are stored in activeUseCaseId & activeUseCase
    const { user, tenantId, activeTenantId = tenantId, appId, activeUseCaseId = appId } = session;
    // If there's only one option for this user, then make sure that's what we have stored
    // and then return it.
    if (user?.tenant?.length === 1 && user?.tenant?.[0].useCase.length === 1) {
        const onlyUseCaseAndTenant = { useCase: user.tenant[0].useCase[0], tenant: user.tenant[0] };
        return onlyUseCaseAndTenant;
    } else {
        // If there's more than one option for this user, then make sure that's what we have stored
        // and then return it.
        const tenant = user.tenant?.find(tenant => tenant?.['identity:tenant']?._id === activeTenantId);
        const useCase = tenant?.useCase?.find(useCase => useCase?.['metaui:useCase']?._id === activeUseCaseId);
        return { tenant, useCase };
    }
}
