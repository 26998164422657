//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _NavHeading from './NavHeading';
import _NavHeadingRecordList from './NavHeadingRecordList';
import _TabNavHeading from './TabNavHeading';
import * as _styles from './styles';
export const NavHeading = _NavHeading;
export const NavHeadingRecordList = _NavHeadingRecordList;
export const TabNavHeading = _TabNavHeading;
export const styles = _styles;
export default {NavHeading, NavHeadingRecordList, TabNavHeading, styles};

