import fileImport from './fileImport';
import defaultRule from './willChange_import_import';
import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

const _p = { loadFile: fileImport.getData };
export const _private = _p;

export default {
    verb: 'willChange',
    namespace: 'import',
    relation: 'import',
    // this needs to happen BEFORE the default rule
    priority: defaultRule.priority + 10,
    useCaseIds: [useCaseIds.PALM],
    description: 'Map and add alternative title to the dataModel to enabled better match with AMI004',
    logic: willChange
};

/**
 *
 * @param {object} message
 * @param {Array<Array<string|number|bool|Date>>} message.newRecord
 * @returns
 */
async function willChange({ data }) {
    let { propertyName, newValue, newRecord } = data;
    if (propertyName !== 'file' || !newValue?.length) return data;
    const file = newValue[0];
    const dataSample = (await _p.loadFile(file)).slice(0, 1);
    if (isAMI004(dataSample)) {
        newRecord.dataModel = mapAMI004DataModel(newRecord.dataModel);
    }
    return { ...data, newRecord };
}

const AMI004Headers = [
    'TAG_NUMBER',
    'SERIAL_ID',
    'ASSET_ID',
    'DEPTID',
    'MODEL',
    'LOCATION',
    'AREA ID',
    'DESCR',
    'CUSTODIAN',
    'EMPLID',
    'CUSTODIAN_DEPTID',
    'MANUFACTURER',
    'MATCH_STATUS',
    'REVIEW_SW',
    'IP_ADDRESS',
    'IP_ALIAS',
    'SOFTWARE_VERSION',
    'IT_ASSET_ID',
    'SCAN_BUSINESS_UNIT',
    'HAND_HELD_OPERATOR',
    'COMMENTS'
];
function isAMI004(dataSample) {
    const headers = dataSample[0].map(c => c.toUpperCase());
    // it is AMI004 if (at least) all known AMI004 headers are included
    return AMI004Headers.every(h => headers.includes(h));
}

const AMI004_ALIASES = {
    assetNo: ['TAG_NUMBER'],
    serialNo: ['SERIAL_ID'],
    palmAssetId: ['ASSET_ID'],
    'location:location.title': ['LOCATION'],
    'organization:organization.title': ['DEPTID'],
    'item:model.title': ['MODEL'],
    'item:manufacturer.title': ['MANUFACTURER'],
    description: ['DESCR'],
    custodian: ['CUSTODIAN'],
    notes: ['COMMENTS']
};

const AMI004_PASSTHROUGH_FIELDS = [
    {
        _meta: {
            id: 'areaId',
            hNodeType: 'DropDownWithFilter',
            hNodeTypeGroup: 'formElement',
            title: 'Area ID',
            propertyName: 'areaId',
            foreignNamespace: 'location',
            foreignRelation: 'location',
            alias: ['AREA ID']
        }
    },
    {
        _meta: {
            id: 'custodianEmployeeID',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'EmployeeId',
            propertyName: 'custodianEmployeeID',
            alias: ['EMPLID']
        }
    },
    {
        _meta: {
            id: 'custodianDepartmentID',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Custodian Department ID',
            propertyName: 'custodianDepartmentID',
            alias: ['CUSTODIAN_DEPTID']
        }
    },
    {
        _meta: {
            id: 'ipAddress',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'IP Address',
            propertyName: 'ipAddress',
            alias: ['IP_ADDRESS']
        }
    },
    {
        _meta: {
            id: 'ipAlias',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'IP Alias',
            propertyName: 'ipAlias',
            alias: ['IP_ALIAS']
        }
    },
    {
        _meta: {
            id: 'itAssetId',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'IT Asset ID',
            propertyName: 'itAssetId',
            alias: ['IT_ASSET_ID']
        }
    },
    // and the fields we don't care about.
    {
        _meta: {
            id: 'softwareVersion',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Software Version',
            propertyName: 'softwareVersion',
            alias: ['SOFTWARE_VERSION'],
            excludeFromImport: true
        }
    },
    {
        _meta: {
            id: 'scanBusinessUnit',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Scan Business Unit',
            propertyName: 'scanBusinessUnit',
            alias: ['SCAN_BUSINESS_UNIT'],
            excludeFromImport: true
        }
    },
    {
        _meta: {
            id: 'handHeldOperator',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Hand Held Operator',
            propertyName: 'handHeldOperator',
            alias: ['HAND_HELD_OPERATOR'],
            excludeFromImport: true
        }
    },
    {
        _meta: {
            id: 'matchStatus',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Match Status',
            propertyName: 'matchStatus',
            alias: ['MATCH_STATUS'],
            excludeFromImport: true
        }
    },
    {
        _meta: {
            id: 'reviewSW',
            hNodeType: 'ShortText',
            hNodeTypeGroup: 'formElement',
            title: 'Review SW',
            propertyName: 'reviewSW',
            alias: ['REVIEW_SW'],
            excludeFromImport: true
        }
    }
];

function mapAMI004DataModel(dataModel) {
    // append data models with alias information
    let result = dataModel.map(model => {
        const { propertyName, foreignNamespace, foreignRelation, propertyPath } = model._meta;
        const property =
            (propertyPath ? propertyPath + '.' : '') +
            (foreignNamespace ? `${foreignNamespace}:${foreignRelation}.` : '') +
            propertyName;
        model._meta.alias = AMI004_ALIASES[property];
        return model;
    });

    // add missing fields, not correctly extracted by our current data Dictionary extraction,
    // and fields we don't care about, but need to import just for the sake of exporting them
    // if they are not included yet.
    AMI004_PASSTHROUGH_FIELDS.forEach(field => {
        if (!result.find(m => m._meta.propertyName === field._meta.propertyName)) {
            result.push(field);
        }
    });
    return result;
}
