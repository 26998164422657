import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'willNew',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    description: 'Create a default record for havens',
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: willNew
};
function willNew({ context, dispatch }) {
    // Prepopulate the haven with a basic environment so it can be created immediately.
    const email = context.user.userName;
    const firstPartEmail = email.substring(0, email.indexOf('@'));
    // This needs to be DNS-1035 compatible since it will be used as a label for kubernetes resources
    // Replace anything not a-z, 0-9, or hyphen
    const pseudoRandomTitle = (firstPartEmail + '-' + Math.random().toString(36).substring(7)).replace(
        /[^a-z0-9-]|[.]/g,
        '-'
    );
    const newRecord = {
        title: pseudoRandomTitle,
        isHaven: true,
        release: '<Most Recent>',
        destroyTime: new Date(Date.now() + 1000 * 60 * 60 * 12),
        tenant: [
            {
                title: pseudoRandomTitle,
                useCase: [
                    {
                        'metaui:useCase': {
                            title: 'Asset Tracking',
                            _id: '100000000000100000000077'
                        }
                    }
                ]
            }
        ]
    };
    // Set form to dirty so that the submit button is enabled.
    dispatch(
        { isHaven: true },
        { verb: 'beginChange', namespace: 'deploy', relation: 'environment', type: 'singleRecordForm' },
        { waitForSubscriber: true }
    );
    return { newRecord };
}
