//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _registerMainServiceWorker from './registerMainServiceWorker';
import _unregisterRedirectWorker from './unregisterRedirectWorker';
export const registerMainServiceWorker = _registerMainServiceWorker;
export const unregisterRedirectWorker = _unregisterRedirectWorker;
export default {registerMainServiceWorker, unregisterRedirectWorker};

