import { constants } from 'lib_ui-services';
import basicWillExport from '../../namespace/relation/export_namespace_relation/willExport_namespace_relation';
import { UserError } from '@sstdev/lib_logging';
import { format } from 'date-fns';
// define this here to keep any reference to AMI008 contained to this one file.
const AMI008 = {
    _id: '6',
    title: 'AMI008',
    contentType: 'text/csv',
    description: 'PALM Inbound Asset Physical Inventory CSV File (AMI008)',
    fileExt: '.csv',
    bookType: 'csv'
};

export default {
    verb: 'willExport',
    namespace: 'item',
    relation: 'item',
    description: 'Intercept export definition to tweak for AMI008',
    // we want this to run AFTER the default willExport, letting it take care
    // of communicating with the user, etc
    priority: (basicWillExport.priority || 0) - 5,
    useCaseIds: [constants.useCaseIds.PALM],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ context }) => {
                if (context?.type?.toUpperCase() !== AMI008.title) {
                    //avoid lookup, as lookup will happen later. Throw error to abort prerequisite workflow
                    // (does not abort entire workflow!)
                    throw new UserError('Request is not for AMI008');
                }
                return {
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    logic: willExport
};

async function willExport({ data, prerequisiteResults, context, ...rest }) {
    if (context.type?.toUpperCase() !== AMI008.title) {
        return { data, ...rest, context };
    }

    const prerequisiteResult = prerequisiteResults[0];
    const result = prerequisiteResult?.result ?? [];

    // Do we need to prompt here for Business Unit?

    // add a MATCH_STATUS and REVIEW_SW field to each record
    // MATCH_STATUS is their way of recording the inventory state:
    // O if it was found, but not originally part of the inventory
    // U if it was part of the inventory but not found
    // I if it was part of the inventory and found
    // REVIEW_SW: Review In Software (or something like that)
    // N: When scanned with a scanner
    // Y: When manually entered
    const records = result.map(x => {
        if (x.inventory?.[0]?.isNew) {
            x.matchStatus = 'O'; // "Over"
        } else if (x.inventory?.[0]?.found) {
            x.matchStatus = 'I'; // "Inventory"
        } else {
            x.matchStatus = 'U'; // "Under"
        }
        x.reviewSW = x.inventory?.[0]?.foundByScan ? 'N' : 'Y';
        // eslint-disable-next-line no-undef
        x.SOFTWARE_VERSION = x.SOFTWARE_VERSION || __PACKAGE_VERSION__;
        return x;
    });

    // set the file type definition, as well as stuff in properly prepared data
    // if we don't set it a file type, it will export to .xlsx by default, which we don't want.

    const timestamp = format(new Date(), "yyyyMMMdd'T'hhmmaaa");
    const fileName = `${AMI008.title}_${timestamp}`;
    const newData = {
        ...data,
        namespace: 'item',
        relation: 'item',
        filePrefix: AMI008.title,
        fileName,
        fileType: AMI008,
        data: records,
        columns: AMI008_COLUMN_DEFINITIONS
    };

    return { data: newData, ...rest, context };
}

const AMI008_COLUMN_DEFINITIONS = [
    {
        sequence: 0,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'TAG_NUMBER',
        propertyName: 'assetNo'
    },
    {
        sequence: 1,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'SERIAL_ID',
        propertyName: 'serialNo'
    },
    {
        sequence: 2,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'ASSET_ID',
        propertyName: 'palmAssetId'
    },
    {
        sequence: 3,
        hNodeType: 'DropDownWithFilter',
        hNodeTypeGroup: 'formElement',
        title: 'DEPTID',
        propertyName: 'title',
        foreignNamespace: 'organization',
        foreignRelation: 'organization'
    },
    {
        sequence: 4,
        hNodeType: 'DropDownWithFilter',
        hNodeTypeGroup: 'formElement',
        title: 'MODEL',
        propertyName: 'title',
        foreignNamespace: 'item',
        foreignRelation: 'model'
    },
    {
        sequence: 5,
        hNodeType: 'DropDownWithFilter',
        hNodeTypeGroup: 'formElement',
        title: 'Location',
        propertyName: 'title',
        foreignNamespace: 'location',
        foreignRelation: 'location'
    },
    {
        sequence: 6,
        hNodeType: 'DropDownWithFilter',
        hNodeTypeGroup: 'formElement',
        title: 'Area ID',
        propertyName: 'areaId',
        foreignNamespace: 'location',
        foreignRelation: 'location'
    },
    {
        sequence: 7,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'DESCR',
        propertyName: 'description'
    },
    {
        sequence: 8,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'CUSTODIAN',
        propertyName: 'custodian'
    },
    {
        sequence: 9,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'EMPLID',
        propertyName: 'custodianEmployeeID'
    },
    {
        sequence: 10,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'CUSTODIAN_DEPTID',
        propertyName: 'custodianDepartmentID'
    },
    {
        sequence: 11,
        hNodeType: 'DropDownWithFilter',
        hNodeTypeGroup: 'formElement',
        title: 'MANUFACTURER',
        propertyName: 'title',
        foreignNamespace: 'item',
        foreignRelation: 'manufacturer'
    },
    {
        sequence: 12,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'MATCH_STATUS',
        propertyName: 'matchStatus'
    },
    {
        sequence: 13,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'REVIEW_SW',
        propertyName: 'reviewSW'
    },

    {
        sequence: 14,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'IP_ADDRESS',
        propertyName: 'ipAddress'
    },
    {
        sequence: 15,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'IP_ALIAS',
        propertyName: 'ipAlias'
    },
    {
        sequence: 16,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'SOFTWARE_VERSION',
        propertyName: 'softwareVersion'
    },
    {
        sequence: 17,
        id: 'itAssetId',
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'IT_ASSET_ID',
        propertyName: 'itAssetId'
    },
    {
        sequence: 18,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'SCAN_BUSINESS_UNIT',
        propertyName: 'scanBusinessUnit'
    },
    {
        sequence: 19,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'HAND_HELD_OPERATOR',
        propertyName: 'inventory[0].foundBy.displayName'
    },
    {
        sequence: 20,
        hNodeType: 'ShortText',
        hNodeTypeGroup: 'formElement',
        title: 'COMMENTS',
        propertyName: 'notes'
    }
];
