import lodash from 'lodash';
const { cloneDeep } = lodash;

/**
 * @param {string} value
 */
const normalize = value => value.toLowerCase().replace(/[^0-9a-z]/gi, '');

/**
 *
 * @param {Array<Array<string|number|bool|Date>>} data
 * @param {boolean} firstRowContainsHeader
 * @param {Array<object>} dataModel
 */
export default async function guessColumns(data, firstRowContainsHeader, dataModel) {
    return new Promise(resolve => {
        let columns = undefined;

        if (firstRowContainsHeader) {
            //simplify the dataModel
            const entityKeys = dataModel.map(model => {
                const title = normalize(model._meta.title);
                const propertyName = normalize(model._meta.propertyName);
                // Aliases are known alternative field names that might be used as title headers on import files
                // The place to currently set those programmatically, in in the willChange_import_import.
                // See for instance willChange_import_import_PALM.js which maps the known/predefined AMI004 format titles to our properties.
                const alias = model._meta.alias?.map(normalize);
                return {
                    title,
                    propertyName,
                    model,
                    alias
                };
            });

            //take the first row, we're told it is the column headers
            /** @type {T} columns */

            const normalizedHeaders = data[0].map(normalize);

            columns = Array(normalizedHeaders.length).fill(null);

            const handleMatchingColumn = (matchingColumn, index) => {
                //remove the entityKey as an option for subsequent matches
                //but only if it is not an array type
                //because if it is, we need a clone, rather than a reference
                if (matchingColumn && matchingColumn.model._meta) {
                    if (matchingColumn.model._meta.isArrayElement) {
                        matchingColumn = cloneDeep(matchingColumn);
                    } else {
                        entityKeys.splice(entityKeys.indexOf(matchingColumn), 1);
                    }
                } //all we really want is the model

                columns[index] = matchingColumn.model;
            };

            // first grab perfect matches
            normalizedHeaders.forEach((header, index) => {
                let matchingColumn = entityKeys.find(
                    key => key.title === header || key.alias?.map(normalize)?.includes(header)
                );
                if (!matchingColumn) return; // no good match
                handleMatchingColumn(matchingColumn, index);
            });

            // next grab matches where header includes title or vice versa
            normalizedHeaders.forEach((header, index) => {
                if (columns[index] != null) return; // already found a better match
                let matchingColumn = entityKeys.find(key => key.title.includes(header) || header.includes(key.title));
                if (!matchingColumn) return; // no good match
                handleMatchingColumn(matchingColumn, index);
            });

            // next grab partial property name matches
            normalizedHeaders.forEach((header, index) => {
                if (columns[index] != null) return; // already found a better match
                let matchingColumn = entityKeys.find(key => key.propertyName.includes(header));
                if (!matchingColumn) return; // no good match
                handleMatchingColumn(matchingColumn, index);
            });

            // any columns that remain null should be empty objects
            columns = columns.map(col => col ?? {});
        } else {
            columns = [...dataModel.filter(x => x._meta.required), ...dataModel.filter(x => !x._meta.required)];

            //Now take the longest row in our preview, and adjust our columns to match that length:
            columns = data.reduce((a, b) => (a.length >= b.length ? a : b), []).map((x, i) => columns[i] || {});
        }

        setArrayIndexes(columns);
        resolve(cloneDeep(columns));
    });
}

function setArrayIndexes(columns) {
    columns.forEach((element, i) => {
        if (element._meta && element._meta.isArrayElement) {
            //set the index to the count of the same column that came before
            element._meta.arrayIndex = columns.filter(
                (c, index) => index < i && c._meta && c._meta.title === element._meta.title
            ).length;
        }
    });
}
