import { createElement as rc, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import HNode from '../../HNode';

/**
 *
 * @param {object} props
 * @param {object} props.hNode
 * @param {Array<'MOBILE' | 'TABLET' | 'DESKTOP' | string>} props.hNode.value
 * @param {Array<object>} props.hNode.children
 * @returns {JSX.Element}
 */
const ShowForViewport = props => {
    const { viewPort } = useContext(ThemeContext);

    const {
        hNode: {
            value: allowedViewPorts,
            children: [hNode]
        },
        ...restOfProps
    } = props || { hNode: {} };

    if (viewPort && allowedViewPorts && allowedViewPorts.length && !allowedViewPorts.includes(viewPort)) {
        return null;
    }

    return rc(HNode, { ...restOfProps, hNode });
};

ShowForViewport.propTypes = {
    hNode: PropTypes.object
};
export default ShowForViewport;
