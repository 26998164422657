import lodash from 'lodash';
const { cloneDeep } = lodash;
import fileImport from './fileImport';
import guessColumns from './guessColumns';

const _p = { guessColumns, loadFile: fileImport.getData };
export const _private = _p;

export default {
    verb: 'willChange',
    namespace: 'import',
    relation: 'import',
    priority: 0,
    description: 'When a file is selected, try to guess the columns',
    logic: willChange
};

async function willChange({ data }) {
    let { propertyName, newValue, newRecord } = data;
    if (propertyName !== 'file') return data;

    // file was changed. Update our guess of the columns
    let { columns, firstRowContainsHeader, dataModel } = newRecord;
    let dataSample = [];
    //if the file was removed
    if (!newValue?.length) {
        //reset the columns to the dataModel
        columns = cloneDeep(dataModel);
    } else {
        const file = newValue[0];
        dataSample = (await _p.loadFile(file)).slice(0, 5);
        columns = await _p.guessColumns(dataSample, firstRowContainsHeader, dataModel);
    }

    return { ...data, newRecord: { ...newRecord, columns, dataSample } };
}
